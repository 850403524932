body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240, 242, 245);
}

/* .RaLayout-contentWithSidebar */
.RaSidebar-docked {
  margin-top: -48px !important;
  height: 100vh !important;
}
.RaSidebar-fixed {
  height: 100vh !important;
}
.RaLayout-content {
  padding: 16px !important;
  background-color: #f0f0f0 !important;
}

/**
 * Hirachy structure for react-admin List:
 *  .list-page
 *  .RaList-content
 *  .RaList-root
 *  .RaDatagrid-tableWrapper
 *  .RaDatagrid-table
 */
 .list-page {
  flex: 1;
}
.RaList-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/* .RaList-root style is defined RaDatagrid component */
.RaDatagrid-tableWrapper {
  position: absolute;
}
.RaDatagrid-table {
  width: max-content !important;
}

ul {
  padding-inline-start: 16px;
}
ol {
  padding-inline-start: 16px;
}
dl {
  padding-inline-start: 0;
}
dd {
  margin-left: 0;
}
